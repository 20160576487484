<template>
  <main>
    <header>
      <div class="socials">
      </div>
      <img src="/static/icons/cutok.svg" alt="" class="main-logo"/>
      <a href="tel:+79663040481" class="phone">
        +7 (966) 304-04-81
      </a>
    </header>
    <section class="main">
      <img
          v-for="i in 8"
          :key="i"
          :src="`/static/img/main-pic (${i}).jpg`"
          alt=""
          :class="{ 'active-img': activeImg === i }"
      />
      <div class="main-txt">
        <h1 class="desc">
					<span v-for="(t, k) in '-25% на первую стрижку'" :key="k">
						{{ t }}
					</span>
        </h1>
        <h1 class="mobile">
          -25% на первую стрижку
        </h1>
        <button class="ms-button" @click="openModal('.modal')">Записаться</button>
      </div>
    </section>
    <section class="services">
      <h2 class="desc">
				<span v-for="(t, k) in 'Услуги'" :key="k">
					{{ t }}
				</span>
      </h2>
      <h2 class="mobile">
        Услуги
      </h2>
      <ul class="service-list">
        <li v-for="(s, k) in services" :key="k">
          <p>{{ s.name }}</p>
          <p>{{ s.price }}</p>
        </li>
      </ul>
      <div class="logo-container">
        <p class="card">Косметическая карта CUT-OK</p>
        <div class="logos">
          <img
              v-for="k in 7"
              :key="k"
              :src="`/static/icons/10${k}.png`"
              alt=""
          />
        </div>
      </div>
    </section>
    <section class="bottom">
      <a class="" href="https://goo.gl/maps/NEntc7zbZCT3Jo2x5">
        <span>Санкт-Петербург</span>
        <p>
          Люблинский переулок, 9
        </p>
      </a>
      <div class="bottom-socials">
        <a href="tel:+79663040481" class="phone">
          +7 (966) 304-04-81
        </a>
      </div>
      <div class="part">
        <a href="https://placebo25.com/ru">
          Part of Placebo/25
        </a>
        <p>CUT-OK ® 2021 - 2024</p>
      </div>
    </section>
    <div class="modal">
      <button class="close" @click="closeModal('.modal')">X</button>
      <iframe id="frame-client" src="https://b447278.yclients.com/company/424006/menu?o="></iframe>
    </div>
  </main>
</template>
<script>
import anmMixin from "@/anmMixin";

export default {
  mixins: [anmMixin],
  data() {
    return {
      services: [
        {name: "Первая стрижка", price: "1500"},
        {name: "Стрижка", price: "2000"},
        {name: "Стрижка бороды", price: "1500"},
        {name: "Стрижка по машинку", price: "1500"},
        {name: "Стрижка головы и бороды", price: "3000"},
        {name: "Стрижка под машинку + борода", price: "2500"},
        {name: "Стрижка бороды + тонирование", price: "3000"},
        {name: "Бритье головы", price: "2000"},
        {name: "Бритье лица", price: "2000"},
        {name: "Бритье головы и лица", price: "3500"},
        {name: "Уход за лицом", price: "2000"},
        {name: "Ваксинг", price: "700"},
        {name: "Укладка", price: "700"},
      ],
      activeImg: 1,
    };
  },
  methods: {
    runMetrika() {
      (function (m, e, t, r, i, k, a) {
        m[i] =
            m[i] ||
            function () {
              (m[i].a = m[i].a || []).push(arguments);
            };
        m[i].l = 1 * new Date();
        (k = e.createElement(t)),
            (a = e.getElementsByTagName(t)[0]),
            (k.async = 1),
            (k.src = r),
            a.parentNode.insertBefore(k, a);
      })(
          window,
          document,
          "script",
          "https://mc.yandex.ru/metrika/tag.js",
          "ym"
      );

      window.ym(74061622, "init", {
        id: 74061622,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: false,
        webvisor: true,
        ecommerce: "dataLayer",
      });
    },
    runGA() {
      (function (i, s, o, g, r, a, m) {
        i["GoogleAnalyticsObject"] = r;
        (i[r] =
            i[r] ||
            function () {
              (i[r].q = i[r].q || []).push(arguments);
            }),
            (i[r].l = 1 * new Date());
        (a = s.createElement(o)),
            (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(
          window,
          document,
          "script",
          "https://www.google-analytics.com/analytics.js",
          "ga"
      );
      window.ga("create", "UA-192467844-1", "auto");
      window.ga("require", "displayfeatures");
      window.ga("set", "forceSSL", true);
      window.ga("send", "pageview");
    },
    runPixel() {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
      );
      window.fbq("init", "1437792833264362");
      window.fbq("track", "PageView");
    },
    runMetrics() {
      return new Promise((resolve) => {

        this.runMetrika();
        this.runPixel();
        this.runGA();

        resolve();

      }).catch(console.error);
    },
    openModal(el) {
      const modalElement = document.querySelector(el);
      modalElement.classList.add('show')
    },
    closeModal(el) {
      const modalElement = document.querySelector(el);
      modalElement.classList.remove('show')
    }
  },
  mounted() {
    if (process.env.NODE_ENV === "production") {
      this.runMetrics();
    }

    this.scrlAnmOn("h1", "fadeGrm", window.innerHeight);
    this.scrlAnmOn("#p1", "fadeUp", window.innerHeight);
    this.scrlAnmOn("#htxt", "fade", window.innerHeight);
    this.scrlAnmOn("h2", "fadeGrm", window.innerHeight, 700);
    document.addEventListener("scroll", () => {
      this.scrlAnmOn("h2", "fadeGrm", window.innerHeight);
      this.scrlAnmOn(".service-list", "fadeList", 500);
      this.scrlAnmOn(".logos", "fadeImg", 500);
      this.scrlAnmOn(".card", "fade", 300);
    });
    /* document.addEventListener('click', () => {
       const isOpen = document.querySelector('.modal').classList.contains('show')
       if (isOpen) {
         this.closeModal('.modal')
       } else {
         return null
       }
     })*/
    // const frame = document.querySelector('#frame-client');
    /*    frame.addEventListener("load", ev => {
          console.log("ev", frame.contentWindow.parent.document);
          const new_style_element = document.createElement("style");
          new_style_element.setAttribute('type', 'text/css');
          new_style_element.innerHTML = "\n" +
              "html {\n" +
              "  font-size: 0.8333333333vw;\n" +
              "}"
          setInterval(() => {
            const headFrame = frame.contentWindow.parent.document.querySelector('head')
            console.log('test', frame.contentWindow.parent.document.querySelector('head'))
            headFrame.appendChild(new_style_element);
          }, 1000)
        });*/

    window.scrollTo(0, 0);
    setInterval(() => {
      this.activeImg = (this.activeImg % 8) + 1;
    }, 4000);
  },
};
</script>

<style lang="scss">
@import "./fonts.scss";
@import "./animations.scss";

$size-base: 16px;

@function ptr($size) {
  @return #{$size / $size-base}rem;
}

$sub-btn-color: rgba(82, 95, 218, 0.67);
$text-color: white;

$main-font: "HelveticaNeueCyr", sans-serif;
$h-font: "Sverdlovsk", sans-serif;

html {
  font-size: 0.8333333333vw;
}

@media (max-width: 900px) {
  html {
    font-size: 5vw;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  font-family: $main-font;

  &::selection {
    background: #931f1f;
    color: rgb(255, 255, 255);
  }

  &:focus {
    outline: none;
  }
}


body {
  width: 100%;
  background-image: url("/static/img/back.png");
  background-color: #000000;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}

section,
header {
  position: relative;
}

header {
  display: grid;
  grid-template-columns: ptr(300px) auto ptr(300px);
  align-items: flex-start;
  position: absolute;
  width: 100%;
  top: ptr(40px);
  padding: 0 ptr(30px);
  z-index: 10;
}

.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  min-height: 400px;
  height: 80vh;
  max-height: 700px;
  z-index: 99;

  &.show {
    display: block;
  }
}

#frame-client {
  border: none;
  border-radius: 35px;
  overflow: hidden;
  max-width: 600px;
  width: 100%;
}

.modal .close {
  margin-left: auto;
  margin-right: 0;
  display: block;
  cursor: pointer;
}

.modal iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.socials {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  gap: ptr(9px);

  a {
    width: ptr(30px);
    height: ptr(30px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.11);

    img {
      width: ptr(11px);
      height: ptr(13px);
      object-fit: contain;
      transition: 0.2s $anmTrs;
    }

    transition: 0.2s $anmTrs;

    &:hover {
      background: white;

      img {
        filter: invert(1);
      }
    }
  }
}

.main-logo {
  justify-self: center;
}

.phone {
  font-size: ptr(20px);
  color: white;
  text-decoration: none;
  font-weight: 200;
  justify-self: flex-end;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 120vh;
  width: 100%;

  &::after {
    content: "";
    width: 100%;
    height: 50%;
    background: linear-gradient(rgba(0, 0, 0, 0), black);
    position: absolute;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0;
    object-fit: cover;
    transition: opacity 1s .2s ease-in-out;
    filter: brightness(.5) grayscale(1);
    user-select: none;
  }

  .active-img {
    z-index: 1;
    opacity: 1;
  }

  button {
    width: ptr(240px);
    height: ptr(50px);
    background: #931f1f;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: none;
    border-radius: 0;
    position: relative;
    top: ptr(77px);
    cursor: pointer;
    font-family: $main-font;
    font-size: ptr(16px);
    z-index: 3;
    transition: 0.3s $anmTrs;
    opacity: 0;
    animation: minfadeUp $timing 0.5s $anmTrs forwards;
    padding: 0;

    &:hover {
      background: #ad2222;
    }
  }
}

.main-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15vh;
  z-index: 3;
}

h1 {
  font-size: ptr(70px);
  font-weight: 200;
  letter-spacing: ptr(-8.5px);
  line-height: ptr(65px);
  overflow: hidden;
  position: relative;
  width: max-content;
  color: white;
  white-space: break-spaces;

  span {
    font-family: $h-font;
    display: inline-block;
    opacity: 0;
  }
}

.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3;
  margin-top: -27vh;
}

h2 {
  font-size: ptr(70px);
  letter-spacing: ptr(4.52px);
  color: white;

  span {
    font-family: $h-font;
    display: inline-block;
    font-weight: 200;
    opacity: 0;
  }
}

ul {
  margin: ptr(60px) 0 0;
  padding: 0;
  display: grid;
  gap: ptr(20px);

  li {
    list-style: none;
    color: white;
    display: flex;
    width: ptr(636px);
    font-size: ptr(26px);
    font-weight: 200;
    justify-content: space-between;
    letter-spacing: ptr(1px);
    opacity: 0;
  }
}

.logo-container {
  margin-top: ptr(170px);

  p {
    font-size: ptr(19px);
    margin-bottom: ptr(20px);
    opacity: 0;
    font-weight: 200;
    color: #7d7d7d;
    text-align: center;
  }
}

.logos {
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
  justify-content: center;
  width: ptr(815px);

  img {
    height: ptr(120px);
    object-fit: contain;
    opacity: 0;

    &:nth-child(7) {
      height: ptr(120px);
      width: ptr(141px);
      margin-left: ptr(-13px);
    }

    &:nth-child(6) {
      width: ptr(102px);
      margin-left: ptr(75px);
    }

    &:nth-child(5) {
      width: ptr(92px);
      margin-left: ptr(92px);
    }

    &:nth-child(4) {
      width: ptr(130px);
    }

    &:nth-child(3) {
      width: ptr(95px);
      margin-left: ptr(90px);
    }

    &:nth-child(2) {
      margin-left: ptr(95px);
      width: ptr(83px);
    }

    &:nth-child(1) {
      margin-left: ptr(70px);
      width: ptr(144px);
    }
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ptr(200px);

  a {
    font-weight: 200;
    text-align: center;
    color: white;
    text-decoration: none;

    p {
      font-size: ptr(36px);
      margin: ptr(15px) 0 ptr(66px);
    }

    span {
      font-size: ptr(19px);
      color: #7d7d7d;
    }
  }
}

.bottom-socials {
  display: flex;
  justify-content: center;
}

.part {
  margin-top: ptr(160px);
  text-align: center;
  padding: 0 0 ptr(30px);

  a {
    font-weight: 400;
    color: #7d7d7d;
    font-size: ptr(18px);
  }

  p {
    margin-top: ptr(16px);
    font-size: ptr(13px);
    color: #484848;
  }
}

.mobile {
  display: none;
}


@media (max-width: 1024px) {
  .desc {
    display: none;
  }

  .mobile {
    display: block;
  }
  header {
    grid-template-columns: max-content auto;
    top: ptr(24px);
    padding: 0 ptr(14px) 0 ptr(28px);
  }
  .socials {
    display: none;
  }
  .main-logo {
    width: ptr(107px);
    justify-self: flex-start;
  }
  .phone {
    justify-self: flex-end;
    font-size: ptr(16px);
  }

  .main {
    height: 100vh;

    &::after {
      bottom: 15vh;
    }

    img {
      top: 0;
      height: 85vh;
      object-position: 55%;
    }
  }

  .main-txt {
    margin-top: -7vh;

    button {
      top: ptr(52px);
    }
  }

  h1,
  h2 {
    font-size: ptr(40px);
    text-align: center;
    letter-spacing: ptr(4px);
    white-space: unset;
    line-height: ptr(44px);
    width: 100%;
    font-weight: 200;
    font-family: $h-font;
  }

  .services {
    margin-top: -15vh;
  }

  ul {
    gap: ptr(15px);

    li {
      width: ptr(285px);
      font-size: ptr(16px);
      line-height: ptr(22px);
      font-weight: 300;
    }
  }

  .logo-container {
    margin-top: ptr(70px);

    p {
      font-size: ptr(13px);
    }
  }

  .logos {
    width: ptr(308px);
    margin-top: ptr(15px);

    img {
      height: ptr(60px);

      &:nth-child(7) {
        height: ptr(45px);
        width: ptr(61px);
        margin-left: ptr(-13px);
      }

      &:nth-child(6) {
        height: ptr(45px);
        width: ptr(44px);
        margin-left: ptr(33px);
      }

      &:nth-child(5) {
        height: ptr(45px);
        width: ptr(40px);
        margin-left: ptr(40px);
      }

      &:nth-child(4) {
        width: ptr(45px);
      }

      &:nth-child(3) {
        width: ptr(41px);
        margin-left: ptr(43px);
      }

      &:nth-child(2) {
        margin-left: ptr(41px);
        width: ptr(36px);
      }

      &:nth-child(1) {
        margin-left: ptr(40px);
        width: ptr(42px);
      }
    }
  }
  .bottom {
    margin-top: ptr(100px);

    a {
      p {
        font-size: ptr(24px);
        margin: ptr(10px) 0 ptr(40px);
      }

      span {
        font-size: ptr(14px);
      }
    }
  }

  .part {
    margin-top: ptr(110px);
    padding: 0 0 ptr(60px);

    a {
      font-size: ptr(14px);
    }

    p {
      margin-top: ptr(6px);
      font-size: ptr(10px);
    }
  }
}
</style>
